import Vue from 'vue';
import Vuex from 'vuex';

import moduleContacts from './modules/moduleContacts'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules:{
        contacts:moduleContacts
    }
  })

export default store;
