<template>
    <div class="mainpage">

        <modal />
        
        <div class="mainpage__background">

            <div class="mainpage__line mainpage__line--1" ref="line1"></div>
            <div class="mainpage__line mainpage__line--2" ref="line2"></div>
            <div class="mainpage__line mainpage__line--3" ref="line3"></div>
            <div class="mainpage__line mainpage__line--4" ref="line4"></div>
            <div class="mainpage__line mainpage__line--5" ref="line5"></div>
            <div class="mainpage__line mainpage__line--6" ref="line6"></div>
            <div class="mainpage__line mainpage__line--7" ref="line7"></div>
            <div class="mainpage__line mainpage__line--8" ref="line8"></div>
            <div class="mainpage__line mainpage__line--9" ref="line9"></div>
            <div class="mainpage__line mainpage__line--10" ref="line10"></div>

        </div>


        <lang-switcher ref="switcher" />

        <div class="mainpage__content">
            
            <div class="container container--overvisible">

                <div class="mainpage__logowrap">
                    <img src="@/assets/img/logo.svg"  ref="logo" alt="" class="mainpage__logo">
                </div>

                <div class="row">
                    <div class="col-7 col-start-4 col-sm-10 col-sm-start-2">

                        <div class="mainpage__items">

                            <div class="mainpage__titlewrap">
                                <div class="mainpage__titleover">
                                    <h2 class="mainpage__title" ref="title1">{{ $t("message.title1") }}</h2>
                                </div>

                                <div class="mainpage__titleover">
                                    <h2 class="mainpage__title" ref="title2">{{ $t("message.title2") }}</h2>
                                </div>
                            </div>

                            <div class="mainpage__contacts">
                                <div class="mainpage__contacts-back" ref="contactsback"></div>
                                <div class="mainpage__titleover">
                                    <h3 class="mainpage__contacts-title" ref="contacttitle">{{ $t('message.contacts1') }}</h3>
                                </div>
                                <div class="mainpage__titleover">
                                    <p class="mainpage__contacts-text" ref="contactdes">{{ $t('message.contacts2') }}</p>
                                </div>
                            </div>

                        </div>


                    </div>

                    <div class="col-6 col-start-4 col-sm-10 col-sm-start-2">
                        <div class="mainpage__items">
                            
                            
                            <div class="mainpage__emailwrap" ref="email">
                                <p class="mainpage__email">
                                    {{ $t('message.emailText') }} 
                                    <a href="mailto:info@projectoffice.kz" class="mainpage__link">info@projectoffice.kz</a>
                                </p>
                            </div>

                            <div class="mainpage__forms" v-if="!sended" ref="forms">

                                <h3 class="mainpage__forms-title">{{ $t('message.formTitle') }}</h3>
                        
                                <div v-if="!emailStatus.error" class="mainpage__forms-error">
                                    <p v-html="emailStatus.errorMessage"></p>
                                </div>

                                <app-form v-model="name" type="input" :placeholder="$t('message.placeholder1')" />
                                <app-form v-model="email" type="input" :placeholder="$t('message.placeholder2')"  />
                                <app-form v-model="phone" type="input" mask="+#( ### ) ### - ## - ##" :placeholder="$t('message.placeholder3')"  />
                                <app-form v-model="message" type="textarea" :placeholder="$t('message.placeholder4')"  />

                                <div class="mainpage__form-btnwrap" @click="submitForm">
                                    <appbtn :text="$t('message.send')" />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            

        </div>

        

    </div>
</template>

<script>
    import {mapMutations, mapGetters, mapActions} from 'vuex'

    import LangSwitcher from '../../components/layout/LangSwitcher'
    import Appbtn from "../../components/ui/AppBtn"
    import AppForm from "../../components/ui/AppForm"
    import Modal from '../../components/layout/Modal'

    import { validationMixin } from 'vuelidate'
    import { 
        required,
        email,
        minLength,
    } from 'vuelidate/lib/validators'

    import {
        TimelineMax,
        Back,
        Power3,
    } from 'gsap'

    let timeline = new TimelineMax();

    export default {
        name:'Mainpage',
        components:{
            LangSwitcher,
            Appbtn,
            AppForm,
            Modal,
        },
        head:{
            title: {
              inner: 'Cозидание во имя людей'
            },
            meta:[
                {name:'application-name',content:'Cозидание во имя людей'},
                {name:'description',content:'Контакты проектного офиса по координации праздничных мероприятий в честь 30-летия независимости Республики Казахстан'},
           
                {property:'og:title',content:'Cозидание во имя людей'},
                {property:'og:description',content:'Контакты проектного офиса по координации праздничных мероприятий в честь 30-летия независимости Республики Казахстан'},
                {property:'og:image',content:'http://projectoffice.kz/social.jpg'},
                {property:'og:url',content:'http://projectoffice.kz'},
                {property:'og:site_name',content:'Cозидание во имя людей'},

                {itemprop:'name',content:'Cозидание во имя людей'},
                {itemprop:'description',content:'Контакты проектного офиса по координации праздничных мероприятий в честь 30-летия независимости Республики Казахстан'},
                {itemprop:'image',content:'http://projectoffice.kz'},

                {name:'twitter:card',content:'summary_large_image'},
                {name:'twitter:title',content:'Cозидание во имя людей'},
                {name:'twitter:description',content:'Контакты проектного офиса по координации праздничных мероприятий в честь 30-летия независимости Республики Казахстан'},
                {name:'twitter:image:src',content:'http://projectoffice.kz'},
                {name:'twitter:image:alt',content:'http://projectoffice.kz/social.jpg'},

           ],
        },
        mixins: [validationMixin],
        validations:{
            name:{
                required,
            },
            email:{
                required,
                email,
            },
            phone:{
                required,
                minLength:minLength(6)
            },
            message:{
                required,
            },
        },
        computed:{
            ...mapGetters(['emailStatus'])
        },
        mounted(){

            let {
                title1,
                title2,
                contactsback,
                contacttitle,
                contactdes,
                logo,
                email,
                forms,
            } = this.$refs;

            timeline
                .from(logo,.4,{
                    yPercent:110,
                    ease:Power3.easeInOut,
                })
                .from(title1, .6, {
                    yPercent:100,
                    ease:Back.easeInOut,
                })
                .from(title2, .6, {
                    yPercent:100,
                    ease:Back.easeInOut,
                },'-=.3')
                .from(contactsback,.4,{
                    x:'100%',
                    ease:Power3.easeInOut,
                },'-=.3')
                .from(contacttitle, .4,{
                    yPercent:'120',
                    ease:Power3.easeInOut,
                },'-=.2')
                .from(contactdes, .4,{
                    yPercent:'120',
                    ease:Power3.easeInOut,
                },'-=.2')
                .from(email,.6,{
                    opacity:0,
                    alpha:0,
                    ease:Power3.easeInOut
                })
                .from(forms, .6,{
                    opacity:0,
                    alpha:0,
                    ease:Power3.easeInOut
                })
            

                
        },
        data(){
            return{
                name:'',
                email:'',
                phone:'',
                message:'',

                sended:false,
            }
        },
        methods:{
            ...mapMutations(['setFormError', 'setModalStatus']),
            ...mapActions(['sendContactsEmail']),
            
            submitForm(){
                if(
                    !this.$v.name.required||
                    !this.$v.email.required||
                    !this.$v.phone.required||
                    !this.$v.message.required
                ){
                    this.setFormError({
                        error:true,
                        message:"Заполните все поля"
                    })
                } else if(!this.$v.email.email){
                    this.setFormError({
                        error:true,
                        message:"Напишите корректный email адрес"
                    })
                } else if(!this.$v.phone.minLength){
                    this.setFormError({
                        error:true,
                        message:"Напишите корректный номер телефона"
                    })
                } else {
                    this.setFormError({
                        error:false,
                    })

                    this.setModalStatus(true)

                    this.sended = true;

                    this.sendContactsEmail({
                        name:this.name,
                        email:this.email,
                        phone:this.phone,
                        subject:'Сообщение с формы',
                        message:this.message,
                    })
                }

                window.scrollTo(0,0)

            },
        }
    }
</script>

<style lang="scss" scoped>

</style>