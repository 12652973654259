import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import messagesRu from "./ru/messages"
import messagesKz from "./kz/messages"

const messages = {
    ru:messagesRu,
    kz:messagesKz
}


const i18n = new VueI18n({
    locale:'kz',
    messages, 
  })

  export default i18n