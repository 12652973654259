<template>
    <div class="lswitcher" ref="langswitcher">
        <div class="lswitcher__btn" :class="this.$i18n.locale=='kz'?'lswitcher__btn--active':''" @click="changeLang('kz')">КАЗ</div>
        <div class="lswitcher__btn" :class="this.$i18n.locale=='ru'?'lswitcher__btn--active':''" @click="changeLang('ru')">РУС</div>
    </div>
</template>

<script>
    import {
        TimelineMax,
        Power3,
    } from 'gsap'

    let timeline = new TimelineMax

    export default {
        name:'LangSwitcher',
        mounted(){
            let {langswitcher}  = this.$refs

            timeline
                .from(langswitcher, .5, {
                    alpha:0,
                    ease:Power3.easeInOut,
                })
        },
        methods:{
            changeLang(locale){
                this.$i18n.locale = locale
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>