const message ={
    message:{
        title1:"жасампаздыққа толы жылдар",
        title2:"созидание во имя людей",
        contacts1:'Байланыс көздері',
        contacts2:'Қазақстан Республикасы Тәуелсіздігінің  30 жылдығына арналған мерекелік іс-шараларды үйлестіру бойынша жобалық кеңсенің байланыс көздері',
        emailText:'Жалпы сұрақтар бойынша e-mail:',
        formTitle:'Кері байланыс формасы',
        placeholder1:'Аты-жөніңіз:',
        placeholder2:'Электронды поштаңыз',
        placeholder3:'Телефоныңыз',
        placeholder4:'Хабарламаңыз',
        send:'Жіберу',
        thanks:'рақмет!',
        recieved:'Сіздің хабарламаңыз қабылданды',
    }
}

export default message