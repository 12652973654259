<template>
    <div class="appform">

        <input v-if="type=='input'&&!mask" type="text" class="appform__item appform__input" v-model="inputVal" :placeholder="placeholder">
        <input v-if="type=='input'&&mask" v-mask="mask" type="text" class="appform__item appform__input" v-model="inputVal" :placeholder="placeholder">

        <textarea v-if="type=='textarea'" type="text" class="appform__item appform__textarea" v-model="inputVal" :placeholder="placeholder"></textarea>

        <div class="appform__file" v-if='hasfile'>
            <input type="file" accept=".docx, .jpg, .png" multiple  class="appform__file-input">
            <p class="appform__file-text">+ добавить файл</p>
        </div>
    </div>
</template>

<script>
    export default {
        name:'appform',
        computed:{
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
        props:{
            type:{
                type:String,
                required:false,
                default:'input'
            },
            placeholder:{
                type:String,
                required:false,
                default:''
            },
            value:{
                type:String,
                required:false,
                default:''
            },
            hasfile:{
                type:Boolean,
                required:false,
                default:false
            },
            mask:{
                type:String,
                required:false,
                default:null
            }
        }

    }
</script>

<style lang="scss" scoped>

</style>