<template>
    <div class="modal" :class="getModalStatus?'modal--active':''">

        <div class="modal__content">
            <img src="@/assets/img/close.svg" @click="setModalStatus(false)" alt="" class="modal__close">

            <h4 class="modal__title modal__title--mb">{{ $t('message.thanks') }}</h4>
            <h4 class="modal__title">{{ $t('message.recieved') }}</h4>
        </div>

        

    </div>
</template>

<script>
    import {mapGetters,mapMutations} from 'vuex'

    export default {
        name:'Modal',
        computed:{
            ...mapGetters(['getModalStatus'])
        },
        methods:{
            ...mapMutations(['setModalStatus'])
        }
    }
</script>

<style lang="scss" scoped>

</style>