import Vue from 'vue'
import App from './App.vue'

import VueHead from 'vue-head'

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VueYandexMetrika from 'vue-yandex-metrika' 

Vue.use(VueHead)

import router from "./router"
import store from "./store"
import i18n from "./localization"


Vue.use(VueYandexMetrika, {
  id: 61004137,
  router: router,
  env: process.env.NODE_ENV
})

Vue.config.productionTip = false

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
