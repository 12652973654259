<template>
    <div class="appbtn" @click="clickHandler">
        <p class="appbtn__text" v-html="text"></p>
    </div>
</template>

<script>
    export default {
        name:'AppBtn',
        props:{
            text:{
                type:String,
                required:false,
                default:'',
            },
            theme:{
                type:String,
                required:false,
                default:'',
            },
        },
        methods:{
            clickHandler(){
                this.$emit('clickHandler')
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>