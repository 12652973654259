const message ={
    message:{
        title1:"жасампаздыққа толы жылдар",
        title2:"созидание во имя людей",
        contacts1:'контакты ',
        contacts2:'ПроектнОГО офисА по координации праздничных мероприятий в честь 30-летия Независимости Республики Казахстан ',
        emailText:'E-mail по общим вопросам:',
        formTitle:'форма обратной связи',
        placeholder1:'Ваше имя и фамилия',
        placeholder2:'ваш e-mail',
        placeholder3:'Ваш телефон',
        placeholder4:'ВАШЕ СООБЩЕНИЕ',
        send:'отправить',
        thanks:'спасибо!',
        recieved:'ваше сообщение принято',
    }
}

export default message