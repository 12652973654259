const axios = require('axios')
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'

const moduleContacts = {
    state:{
        info:[],
        emailSended:false,
        emailError:false,
        errorMessage:'',
        activeModal:false,

    },
    mutations:{
        setFormError(state, info){
            if(info.error){
                state.error = true;
                state.errorMessage = info.message
            } else {
                state.error = false;
                state.errorMessage = ''
            }
        },

        setModalStatus(state,val){
            state.activeModal = val
        }
    },
    actions:{
        sendContactsEmail({state},info){
            let bodyFormData = new FormData()

            let {
                name,
                email,
                phone,
                subject,
                message,
            } = info

            phone = phone.split('').filter(item=>!isNaN(parseInt(item))).join('');

            bodyFormData.set('name',name)
            bodyFormData.set('email',email)
            bodyFormData.set('phone',phone)
            bodyFormData.set('subject',subject)
            bodyFormData.set('message',message)

            axios
                .post('http://projectoffice.kz/sendmail.php',bodyFormData)
                .then(()=>{
                    state.emailSended = true;
                })
                .catch((error)=>{
                    state.emailError = true
                    state.errorMessage = error
                })
        }
    },
    getters:{
        emailStatus(state){
            return {
                success:state.emailSended,
                error:state.emailError,
                errorMessage:state.errorMessage
            }

        },

        getModalStatus(state){
            return state.activeModal
        }
    }
}

export default moduleContacts